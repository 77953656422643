<template>
  <div>
    <h2>This is BlankBoard</h2>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "blankboard",
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "BlankBoard" },
    ]);

  },

};
</script>